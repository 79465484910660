<template>
    <div class="min-h-screen flex flex-col">
        <HeaderImpersonate></HeaderImpersonate>

        <MenuApp class="grow-0"></MenuApp>

        <div class="mb-auto bg-grey100 grow w-full">
            <slot></slot>
        </div>

        <FooterApp class="grow-0"></FooterApp>

        <ChatFloating></ChatFloating>
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>
